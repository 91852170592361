.NavBar {
    color: antiquewhite;
    font-size: 1.2rem;
}

.Video {
    display: none;
    visibility: hidden;
}

.Spotify {
    color: green;
}

.Apple {
    color: grey;
}

.HideLarge {
    display: block;
    visibility: visible;
}
.Youtube {
    color: red;
}

.Instagram {
    color: purple;
}

.Facebook {
    color: blue;
}

.LinkText {
    font-size: .8rem;
}

@media screen and (min-width: 410px) {
    .NavBar {
        font-size: 1.4rem;
    }
    .LinkText {
        font-size: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .NavBar {
        font-size: 2rem;
    }
    .LinkText {
        font-size: 1.2rem;
    }
    .Video {
        display: block;
        visibility: visible;
        background: transparent;
    }
    .HideLarge,
    .Youtube {
        display: none; 
        visibility: hidden;
    }
}