/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

@import url('https://fonts.googleapis.com/css2?family=Special+Elite&display=swap');

.BandLogo {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgba(0, 0, 0, 0.35);
    font-family: 'Special Elite', cursive;
}

.BandLogo::before {
    background: url(../assets/logo_main_transparent.webp) no-repeat center center fixed;
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.92;
    z-index: -1;
}

.BandLogo::after {
    background: url(../assets/follow_the_herd_backdrop_460x1000.webp) no-repeat center center fixed;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.32;
    z-index: -1;

}

.MainHeading {
    font-size: 1.8rem;
}

@media screen and (min-width: 768px) {
    .BandLogo::after {
        background: url(../assets/follow_the_herd_backdrop_1480x1000.webp) no-repeat center center fixed;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        opacity: 0.35;
    }
    .MainHeading {
        font-size: 3.6rem;
    }
}

@media screen and (min-width: 1024px) {
    .BandLogo::after {
        opacity: 0.38;
    }
}